<app-header></app-header>
<script src="/scripts/snippet-javascript-console.min.js?v=1"></script>

<div class="section">
    <div class="section1">
      <div class="title">
        <h3>Product Specification</h3>
        <h2>Nitrile Gloves</h2>
      </div>
      
      <img class="prod-img center float-lg-right" id="img1" src="./assets/image/Icons/PAGE_5_GLOVE.jpg" />
      <img class="prod-img center float-lg-right" id="img2" src="./assets/image/Icons/PAGE_5_GLOVES_2.jpg" name="2" />
      <img class="prod-img center float-lg-right" id="img3" src="./assets/image/Icons/PAGE_5_GLOVE_WHITE.jpg" name="3" />
      
      <div class="text-center text-lg-left">
        <div class="btn-grp">
          <button id="btn1" data-table="tb1">Description</button>
          <button id="btn2" data-table="tb2">Dimension</button>
          <button id="btn3" data-table="tb3">Properties</button>
        </div>
      </div>

      <table id="tb1">
        <tr>
          <th>Product</th>
          <td>Nitrile Gloves</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>Powder Free</td>
        </tr>
        <tr>
          <th>Product Weight Range</th>
          <td id="weight">3.5G to 5.0G (Available for customization)</td>
        </tr>
        <tr>
          <th>Available Colour</th>
          <td>
            <ul>
              <li data-image="img1"><span data-image="img1">Regular Blue</span></li>
              <li data-image="img2"><span data-image="img2">Black</span></li>
              <li data-image="img3"><span data-image="img3">White</span></li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>Design Features</th>
          <td>Ambidextrous, finger or palm textured</td>
        </tr>
        <tr>
          <th>Finishing</th>
          <td>Online chlorinated</td>
        </tr>
        <tr>
          <th>Packaging</th>
          <td>100 pcs OR 200 pcs per inner box x 10 inner box per carton</td>
        </tr>
      </table>

      <table id="tb2">
        <tr>
          <th>Description</th>
          <th>Dimension (mm)</th>
        </tr>
        <tr>
          <th>Length</th>
          <td id="length">Min 240</td>
        </tr>
        <tr>
          <th colspan="2">Width</th>
        </tr>
        <tr>
          <th>Extra Small (XS)</th>
          <td>75 (±10)</td>
        </tr>
        <tr>
          <th>Small (S)</th>
          <td>85 (±10)</td>
        </tr>
        <tr>
          <th>Medium (M)</th>
          <td>95 (±10)</td>
        </tr>
        <tr>
          <th>Large (L)</th>
          <td>105 (±10)</td>
        </tr>
        <tr>
          <th>Extra Large (XL)</th>
          <td>115 (±10)</td>
        </tr>
      </table>

      <table id="tb3">
        <tr>
          <th>Description</th>
          <th>Specifications</th>
        </tr>
        <tr>
          <th>Force Break</th>
          <td>> 6N</td>
        </tr>
        <tr>
          <th>Elongation at Break</th>
          <td>> 500 %</td>
        </tr>
        <tr>
          <th>Tensile Strength</th>
          <td>> 20 MPa</td>
        </tr>
        <tr>
          <th>Thickness at Finger</th>
          <td>MIN. 0.09MM</td>
        </tr>
      </table>
      <!-- <button class=""><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSeB71W9n9CVTYStasyFtPkegIDYT1j71k5l3HbEmsyBRe3eyA/viewform">Enquire Now</a></button> -->
      <button class=""><a id="enquire" href="./contactus?enquire">Enquire Now</a></button>
      
    </div>

    <div class="section2">
      <h3 class="text-center">Applications</h3>
      <div class="section2-wrapper">
        <div class="grid">
          <div class="grid-item">
            <p class="hidden-md hidden-lg">Medical</p>
            <div class="layer hidden-xs hidden-sm"><p>Medical</p></div>
            <img src="./assets/image/Page5_Applications/medical2.jpg" id="app1">
          </div>
          <div class="grid-item">
            <p class="hidden-md hidden-lg">Food Contact</p>
            <div class="layer hidden-xs hidden-sm"><p>Food Contact</p></div>
            <img src="./assets/image/Page5_Applications/FOOD_CONTACT_(BLUE).jpg" id="app2">
          </div>
          <div class="grid-item">
            <p class="hidden-md hidden-lg">Industrial</p>
            <div class="layer hidden-xs hidden-sm"><p>Industrial</p></div>
            <img src="./assets/image/Page5_Applications/INDUSTRIAL_(BLACK).jpg" id="app3">
          </div>
          <div class="grid-item">
            <p class="hidden-md hidden-lg">Dental</p>
            <div class="layer hidden-xs hidden-sm"><p>Dental</p></div>
            <img src="./assets/image/Page5_Applications/dental.jpg" id="app4">
          </div>
          <div class="grid-item">
            <p class="hidden-md hidden-lg">Chemotherapy Handling</p>
            <div class="layer hidden-xs hidden-sm"><p>Chemotherapy Handling</p></div>
            <img src="./assets/image/Page5_Applications/chemo.jpg" id="app5">
          </div>
          <div class="grid-item">
            <p class="hidden-md hidden-lg">Pharmaceutical</p>
            <div class="layer hidden-xs hidden-sm"><p>Pharmaceutical</p></div>
            <img src="./assets/image/Page5_Applications/pharma.jpg" id="app6">
          </div>
        </div>
      </div>
    </div>
</div>

<app-footer></app-footer>



