<app-header></app-header>

<div class="section">
  <div class="section1-wrapper">
    <img class="section1-image hidden-sm hidden-xs" src="./assets/image/Desktop/PAGE_7_NEWS_01.jpg" />
    <img class="section1-image hidden-lg hidden-md" src="./assets/image/Strip(MobileResponsive)/M_PAGE_7_NEWS_01.jpg" />
    <div class="section1-contents col-lg-7 col-sm-8 offset-sm-0 col-10 offset-0 h-100 d-table">
      <div class="d-table-cell">
        <h3>November 1st, 2021 | TheMalaysianReserve</h3>
        <h2 class="section1">Hong Seng Industries to invest RM3b in world's largest glove factory in Kedah</h2>
        <p class="hidden-sm hidden-xs">Hong Seng Consolidated Bhd's subsidiary Hong Seng Industries Sdn Bhd will invest RM 3 billion in Kedah Rubber City (KRC) to build the world's largest glove factory starting next year.</p>
        <a class="links" href="https://themalaysianreserve.com/2021/11/01/hong-seng-industries-to-invest-rm3b-in-worlds-largest-glove-factory-in-kedah/" target="_blank">Read More ►</a>
      </div>
    </div>
  </div>

  <div class="section2-wrapper">
    <img class="section2-image hidden-sm hidden-xs" src="./assets/image/Desktop/PAGE_7_NEWS_02.jpg" />
    <img class="section2-image hidden-lg hidden-md" src="./assets/image/Strip(MobileResponsive)/M_PAGE_7_NEWS_02.jpg" />
    <div class="section2-contents col-lg-7 col-sm-7 offset-sm-0 col-7 offset-0 h-100 d-table">
      <div class="d-table-cell">
        <h3>June 8th, 2021 | TheStar</h3>
        <h2>Hong Seng's glove business to start ops</h2>
        <p class="hidden-sm hidden-xs">Hong Seng Consolidated Bhd says its first two glove production lines out of the eight planned for this year, should be up and running by the end of this month.</p>
        <a class="links" href="https://www.thestar.com.my/business/business-news/2021/06/08/hong-sengs-glove-business-to-start-ops" target="_blank">Read More ►</a>
      </div>
    </div>
  </div>

  <div class="section3-wrapper">
    <img class="section3-image hidden-sm hidden-xs" src="./assets/image/Desktop/PAGE_7_NEWS_03.jpg" />
    <img class="section3-image hidden-lg hidden-md" src="./assets/image/Strip(MobileResponsive)/M_PAGE_7_NEWS_03.jpg" />
    <div class="section3-contents col-lg-7 col-sm-7 offset-sm-0 col-9 offset-0 h-100 d-table">
      <div class="d-table-cell">
        <h3>February 8th, 2021 | TheStar</h3>
        <h2>Hong Seng banks on gloves and healthcare</h2>
        <p class="hidden-sm hidden-xs">Hong Seng Consolidated Bhd which has been on a buying spree since the emergence of a new shareholder has its sights set on the glove and healthcare industries to carry the company forward.</p>
        <a class="links" href="https://www.thestar.com.my/business/business-news/2021/02/08/hong-seng-banks-on-gloves-and-healthcare" target="_blank">Read More ►</a>
      </div>
    </div>
  </div> 
</div>

<app-footer></app-footer>

