<app-header></app-header>

<div class="section">
  <div class="section1">
    <div class="section1-contents px-sm-5 mx-sm-4">
      <h2 class="col-lg-5 pb-2">Join Hong Seng Gloves Family</h2>
      <p class="col-lg-4">Career opportunities await you in many areas both technically or non-technically.</p>
      <p class="col-lg-4">If you've got what it takes, we would like to hear from you.</p>
    </div>
  </div>

  <div class="section2">
    <h2 class="text-center py-3 py-sm-5">We are hiring.</h2>

    <div class="section2-wrapper" id="tab1">
      <div class="tab1 mb-4 mb-sm-5" >
        <input id="show" type=checkbox>
        <label for="show"  (click)="show('tab1')">
          <div class="tab-show space-between py-1 px-4 py-md-3 px-md-5">
            <div class="tab-item">
              <h4 id="title1">QA/RA</h4>
            </div>
            <div class="tab-item arrow">
              <a id="arrow1">&#9660;</a> <!--DOWNWARD ARROW-->
            </div>
          </div>
        </label>
        <span id="content" class="col-md-12">
          <div class="tab-title space-between">
            <div class="tab-item col-6">
              <h4>DC/RA Executive</h4>
            </div>
            <div class="tab-item col-6">
              <!-- <button id="btn1" data-area="QARA"><a target="_blank" href="https://docs.google.com/forms/d/1AfxzW00G0RhR0OISbQlhkfYlmfFYlkM3XZdqBXhWEXE">Apply Now</a></button> -->
              <button id="btn1" (click)="focusAppForm('QARA')"><b>Apply Now</b></button>
            </div>
          </div>
          <ol>
            <li>Assist the QA/RA Manager in updating, maintaining and control of Quality Manual and Operation Procedures.</li>
            <li>Control of issuance of quality document changes and distribution.</li>
            <li>Ensure only the latest revision and issue of documents is distributed.</li>
            <li>Keep track of all the quality documents.</li>
            <li>Monitoring of active and matured records.</li>
            <li>To ensure all quality records and properly maintained and stored for verification.</li>
            <li>Assist the QA/RA Manager in carrying out regulatory requirements process such as 510K application and renewal of registration and other regulatory or customer requirement, documentation and application.</li>
            <li>To assist QA/RA Manager to initiate internal quality audit at specific time frame to monitor the status of corrective action and follow up audits.</li>
            <li>To co-ordinate on external audit such as SGS and customer audit. Follow-up on corrective actions and feedback to external parties.</li>
            <li>To co-ordinate send samples gloves to external lab testing.</li>
          </ol>
        </span>
      </div>
      </div>
      <div class="section2-wrapper" id="tab2">
      <div class="tab2 mb-4 mb-sm-5" >
        <input id="show2" type=checkbox>
        <label for="show2"  (click)="show('tab2')">
          <div class="tab-show space-between py-1 px-4 py-md-3 px-md-5">
            <div class="tab-item">
              <h4 id="title2">Production</h4>
            </div>
            <div class="tab-item arrow">
              <a id="arrow2">&#9660;</a>
            </div>
          </div>
        </label>
        <span id="content2" class="col-md-12">
          <div class="tab-title space-between">
            <div class="tab-item col-6">
              <h4>Technical Executive</h4>
            </div>
            <div class="tab-item col-6">
              <!-- <button id="btn2" data-area="Production"><a target="_blank" href="https://docs.google.com/forms/d/1AfxzW00G0RhR0OISbQlhkfYlmfFYlkM3XZdqBXhWEXE">Apply Now</a></button> -->
              <button id="btn2" data-area="Production" (click)="focusAppForm('Production')"><b>Apply Now</b></button>
            </div>
          </div>
          <ol>
            <li>Mainly responsible for process validation program for throughout the factory. Leading the validation team to carry out the process validation for new product, new lines, and re-validation.</li>
            <li>To take a lead on the company wide risk management program with other department to establish the process FMEA and ensure updating of such documents and when it is necessary.</li>
            <li>To establish and maintain all the technical documentation.</li>
            <li>To lead and collaborate with other team members on the company wide Cost Improvment Initiative Program. Preparing cost benefit analysis for any improvement program to be engaged and carry out study and analysis on the project feasibility.</li>
            <li>To lead the company wide improvement program for ideas exploration, review, study, analyse and recommend to management for the implementation.</li>
            <li>To involve in the process technical aspect for quality and process improvement. Study and analyse current quality issues and develop the troubleshooting or process improvement.</li>
            <li>To ensure all activities related to work do not give any impact to environment and complying with Good Environmental Practices (GEP).</li>
            <li>To promote awareness on environmental care to all staffs.</li>
            <li>To ensure compliance with all related legislation such as OSHA, FMA, EQA, etc.</li>
          </ol>
        </span>
      </div>
    </div>
  </div>

  <div class="section3" id="app_form">
    <div class="col-md-7 col-7" style="margin-bottom:5%;">
      <p>Please fill in the details and attach your CV/resume or &nbsp;
        <span><a href="https://www.jobstreet.com.my/en/companies/162866268556358-hong-seng-gloves/jobs" target="_blank">
                            <img src="./assets/image/Career(Department)/JobStreet.png "/>
               </a></span></p> 
      <form method="POST" (submit)="sendMail()" class="form-horizontal" role="form" ngNativeValidate>
        <label>Department</label>
        <select id="department_fill" class="form-control"  name="careerDepartment" [(ngModel)]="careerDepartment" [(value)]="careerDepartment" id="careerDepartment" required>
          <option value="defualt" selected disabled hidde>-- Select --</option>
          <option value="QARA">QA/RA</option>
          <option value="Production">Production</option>
        </select>

        <label>Email Address</label>
        <input type="email" class="form-control input-lg" name="careerEmail" [(ngModel)]="careerEmail" [(value)]="careerEmail" id="careerEmail" required>

        <label>Phone Number</label>
        <input type="text" class="form-control input-lg" name="careerPhone" [(ngModel)]="careerPhone" [(value)]="careerPhone" id="careerPhone" required>
        <br><br>

        <div class="btn-grp col-12 d-lg-inline-flex">
          <!--UPLOAD BUTTON-->
          <div class="upload-btn col-lg-7">
            <input type="file" id="actual-btn" name="fileToUpload" (change)="changeListener($event)" hidden/>
            <label for="actual-btn" class="col-8 col-sm-6 col-lg-8">Upload your CV/resume here.</label>
            <span id="file-chosen" class="col-4 col-lg-4">No file chosen</span>
          </div>

          <!--SUBMIT BUTTON-->
          <input type="submit" value="Send Application" class="col-6 col-sm-4 mt-4 mt-lg-0" name="submit">
        </div>
      </form>
    </div>
  </div>
</div>

<app-footer></app-footer>




