import { AnimationKeyframesSequenceMetadata } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
  appForm: any;
  careerDepartment: any;
  careerEmail: any;
  careerPhone: any;
  fileToUpload: any;
  fileName: any;

  constructor(
    private apiService: ApiService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Career | Hong Seng Gloves")

    }

  show(tab) {
    //CHANGE TAB1 ARROW DIRECTION
    this.appForm = document.getElementById('app_form') as HTMLDivElement | null;

    var tabClick = "";
    tabClick =  tab.substring(tab.length - 1);
    // console.log(tabClick);
    
    var arrow1 = document.getElementById('arrow'+tabClick);
    if (arrow1.innerHTML === '\u{25bc}') { //DOWNWARD ARROW
      arrow1.innerHTML = '\u{25b2}'; //UPWARD ARROW
      this.appForm.style.display = "none";
    }
    else if (this.appForm.style.display = "flex") {
      this.appForm.style.display = "none";
      arrow1.innerHTML = '\u{25bc}';
    }
  }

  
  focusAppForm(e) {
    //SHOW APPFORM, SCROLL & FILL IN DEPARTMENT FIELD AUTOMATICALLY  
    this.careerDepartment = "";
    this.careerDepartment = e;
    this.appForm.style.display = "flex";
    this.appForm.scrollIntoView();
  }


  changeListener($event) : void {
    const fileChosen = document.getElementById('file-chosen');
    fileChosen.textContent = $event.target.files[0].name;
    this.fileName = $event.target.files[0].name;
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file:File = inputValue.files[0];
    var myReader:FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.fileToUpload = myReader.result;
    }
    myReader.readAsDataURL(file);
  }
 

  sendMail() {
console.log('here')
      const imgBase64Path =  this.fileToUpload
       var cardImageBase64 = imgBase64Path;
    let data = {
      "mailtype": "J", //mailtype: J - Career
      "careerEmail": this.careerEmail,
      "careerPhone": this.careerPhone,
      "fileToUpload": cardImageBase64,
      "fileName": this.fileName
    }
    // console.log(data1);
    this.apiService.postSendEmail(data).subscribe((res: any) => {
       console.log('response: ', res)

    }, error => {
       console.log('response: ', error)
    })
    Swal.fire(
      'Inquiry Sent!',
      'We will contact you later, Thanks!',
      'success'
    )
  }

}
